import '../styles/global.css';

import { init as initSentry } from '@sentry/react';
import { Analytics } from '@vercel/analytics/react';
import { DefaultSeo } from 'components/DefaultSeo';
import ErrorBoundary from 'components/ErrorBoundary';
import { FontWrapper } from 'components/layout/FontWrapper/FontWrapper';
import { MapGLProvider } from 'hooks/useMap/useMapGL/MapGLContext';
import { setAutoFreeze } from 'immer';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import NextNprogress from 'nextjs-progressbar';
import { ReactElement } from 'react';
import GTM from 'react-gtm-module-custom-domain';
import { Toaster } from 'react-hot-toast';
import { RecoilRoot } from 'recoil';
import { truckMapConfig } from 'truckMapConfig';

setAutoFreeze(false);

const { apiKeys, env, isClientSideRender, url, gtmFile, isSearchBot } = truckMapConfig;

if (apiKeys.sentry.dsn) {
  initSentry({ dsn: apiKeys.sentry.dsn });
}

if (env === 'production' && isClientSideRender && !isSearchBot) {
  GTM.initialize({ gtmId: apiKeys.gtm.id, customURL: `${url}${gtmFile}` });
}

export declare type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
  getLayout: (component: ReactElement) => JSX.Element;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <ErrorBoundary>
      <FontWrapper>
        <RecoilRoot>
          <NextNprogress
            color="#3796FE"
            startPosition={0.3}
            stopDelayMs={0}
            height={2}
            showOnShallow={true}
            options={{
              showSpinner: false
            }}
          />
          <DefaultSeo />
          <MapGLProvider>{getLayout(<Component {...pageProps} />)}</MapGLProvider>
          <Toaster />
          <Analytics />
        </RecoilRoot>
      </FontWrapper>
    </ErrorBoundary>
  );
};

export default App;
